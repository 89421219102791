<template>
  <div @click="this.$store.commit('Lang', false)" v-if="islang">
    <div class="LanguageSelector position-relative" @click="click_lang()">
      <div class="d-flex L-div position-relative">
        <div class="default-selected-language">
          <img class="ddp-icon" :src="who_img" />
        </div>
        <span>{{ who_text }}</span>
        <div class="carretdown-wrapper">
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
              fill="currentcolor"
            ></path>
          </svg>
        </div>
      </div>
      <div
        class="language-dropdown"
        :style="flag_show ? 'visibility: visible' : 'visibility: hidden'"
      >
        <div class="ddp-item active lang-kr" @click="lang('cn')">
          <img
            src="@/assets/img/lang_img/cn.png"
            class="ddp-icon"
            alt="ddp-lang-icon"
          /><span class="ddp-text">简体中文</span>
        </div>
        <div class="ddp-item lang-en" @click="lang('en')">
          <img
            src="@/assets/img/lang_img/us.svg"
            class="ddp-icon"
            alt="ddp-lang-icon"
          /><span class="ddp-text">English</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      locale: localStorage.getItem("locale"),
      islang: true,
      who_img: require("@/assets/img/lang_img/kr.svg"),
      who_text: "한국어",
      flag_show: false,
      isMobile: this.$Utils.isMobile(),
    };
  },
  computed: {},
  watch: {
    locale: function (e) {
      localStorage.setItem("locale", e);
      window.location.reload();
    },
  },
  methods: {
    lang: function (e) {
      this.locale = e;
    },
    mouse_over_flag_t: function () {
      if (this.isMobile == "pc端") {
        this.flag_show = true;
      }
    },
    mouse_over_flag_f: function () {
      if (this.isMobile == "pc端") {
        this.flag_show = false;
      }
    },
    click_lang: function () {
      this.flag_show = !this.flag_show;
      this.$store.commit("flagnav", true);
      if (this.flag_show) {
        // this.$emit("sco_lang", "");
        this.sco_lang();
      }
    },
    sco_lang: function () {
      var ele_id = document.getElementById("scro_left");
      var scrollTop = ele_id.scrollHeight;
      document.getElementById("scro_left").scrollTop = scrollTop;
    },
  },
  created() {
    if (this.locale == "en") {
      this.who_img = require("@/assets/img/lang_img/us.svg");
      this.who_text = "English";
    } else {
      this.who_img = require("@/assets/img/lang_img/cn.png");
      this.who_text = "简体中文";
    }
  },
};
</script>
