<template>
  <div>
    <div class="winner-cotainer">
      <div class="winner-title">
        <img
          src="@/assets/img/game_category/icon-winner.png"
          class="winner-t-img"
        /><span>{{ $t("text_winner_title") }}</span>
      </div>
      <div class="winner-content">
        <div class="winner-div" id="winner-div">
          <div :style="{ transform: 'translateY(-' + scrollTop + 'px)' }">
            <div
              class="winner-item-cotainer d-flex flex-wrap justify-content-between"
              id="winner-item-cotainer"
              v-for="l in 2"
              :key="l"
            >
              <div
                class="winner-item d-flex align-items-center justify-content-between c-pointer"
                v-for="l in 20"
                :key="l"
              >
                <div class="winner-game">
                  <img
                    src="https://images.metaversement.io/images/fachai/21003_EN.png"
                    alt=""
                  />
                  <div class="winner-info d-flex flex-column align-items-start">
                    <span class="winner-name">****5ca</span
                    ><span>Candy Blitz Bombs</span>
                  </div>
                </div>
                <div
                  class="winner-amount d-flex align-items-center justify-content-end position-relative"
                >
                  <span>{{ $t("cur") }}</span> {{ l }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      gamename_arr: [
        "crash",
        "double",
        "dice",
        "keno",
        "mine",
        "crypto",
        "hilo",
        "coin",
        "tower",
      ],
      tableData: [
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
      ],
      timer: "",
      scrollTop: "",
      // boxHeight: "",
      scrollHeight: "",
    };
  },
  methods: {
    scroll() {
      setInterval(() => {
        var new_arr = [];
        var insert_data = [];
        for (var i = 0; i < this.tableData.length; i++) {
          insert_data = [
            {
              name: Math.floor(Math.random() * 9),
              userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
              bet: Math.ceil(Math.random() * 1000 + 500),
              win: Math.ceil(Math.random() * 299 + 100),
            },
          ];
          new_arr.push(insert_data[0]);
        }
        this.tableData = new_arr;
      }, 1000);
    },

    new_scroll() {
      if (this.scrollTop < this.scrollHeight) {
        this.scrollTop = this.scrollTop + 1.5;
      } else {
        this.scrollTop = 0;
      }
    },
  },
  mounted() {
    var ele_id = document.getElementById("winner-div");
    this.scrollTop = ele_id.scrollTop;

    // this.boxHeight = window.getComputedStyle(ele_id).getPropertyValue("height"); //取得div的高度
    // this.boxHeight = parseInt(this.boxHeight);

    this.scrollHeight = document.getElementById(
      "winner-item-cotainer"
    ).scrollHeight;

    this.timer = setInterval(() => {
      this.new_scroll();
    }, 30);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  created() {
    // this.scroll();
  },
  components: {},
};
</script>
