<template>
  <div>
    <div
      style="
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        margin-top: 45px;
      "
    >
      <img
        :src="require('@/assets/img/promotion/' + l + '.png')"
        v-for="l in list"
        :key="l"
        class="w-100"
      />
    </div>
    <!-- <iframe
        :src="getnewurl(index)"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        marginwidth="0"
        marginheight="0"
        scrolling="auto"
        frameborder="0"
        align="center"
      ></iframe> -->
  </div>
</template>
<script>
export default {
  props: { index: Number },
  data() {
    return { list: ["1", "2", "3", "4", "5", "6"] };
  },
  methods: {
    getnewurl: function (t) {
      return (
        process.env.VUE_APP_BASEURL +
        "/promotions_app.aspx?station=" +
        process.env.VUE_APP_STATOIN +
        "&id=" +
        t
      );
    },
  },
};
</script>
