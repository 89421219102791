<template>
  <div class="">
    <div class="home_game_casino">
      <!-- <div class="d-flex justify-content-end">
        <div class="search-input search-input_search__2jkZ6">
          <input
            placeholder="Search game"
            class="input_place"
            v-model="keyWord"
          /><span class="svg-icon q-icon">
            <img
              src="@/assets/img/main/search.png"
              :title="$t('slot_Search')"
            />
          </span>
        </div>
      </div> -->

      <div class="d-flex justify-content-between align-items-center">
        <div class="game_logo_img fw-bolder my-3 align-items-center">
          <img
            :src="
              type.toUpperCase().indexOf('A1-') != -1
                ? require('@/assets/img/allinslot/logo/slot_a1_' +
                    type.split('-')[1].toLowerCase() +
                    '.png')
                : require('@/assets/img/game/' + type + '.png')
            "
            :title="type.toUpperCase()"
            style="height: 25px"
            :style="type == 'hot' ? 'width: 30px;height: 30px' : 'width: 55px;'"
          />
          <span class="text_span">
            {{
              type.toUpperCase().indexOf("A1-") != -1
                ? type.split("-")[1].toUpperCase()
                : type.toUpperCase() == "HOT"
                ? $t("text_main11")
                : type.toUpperCase()
            }}</span
          >
        </div>

        <!-- <span
          v-if="type != 'hot'"
          class="see-all-btn text_span"
          @click="this.$router.push('/slot/providers')"
          >{{ $t("history_back") }}</span
        > -->
      </div>

      <div class="live_casino">
        <div class="live_casino_card">
          <div class="game_slider_card">
            <div
              v-for="l in filterSearch.slice(0, newVal)"
              :key="l"
              class="filter_card game_item text-center"
              @click="game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)"
            >
              <div
                class="filter_card1"
                v-lazy:background-image="'http://gameweb.metaversement.io/' + l.image"
              ></div>
              <!-- <div class="mask position-absolute top-0 start-0"></div> -->
              <div class="game-actions">
                <span class="btn-play">{{ $t("home_text1") }}</span>
              </div>
              <!-- <span class="game_name">namenamenamenamenamename</span> -->
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-flex w-25 mx-auto align-items-center mb-2 justify-content-between w-lg-75 mt-5"
      >
        <span class="w-25">{{ newVal }}/{{ maxVal }}</span>
        <div class="progress mx-2 w-100">
          <div
            class="progress-bar bg-success"
            :style="{
              width: percent + '%',
            }"
          ></div>
        </div>
        <span class="w-25 text-end">{{ percent }}%</span>
      </div>
      <div class="w-25 mx-auto text-center w-lg-75">
        <button
          class="w-100 btn btn-success main_color"
          @click="more()"
          v-show="this.percent == 100 || this.percent == 0 ? false : true"
        >
          <a>{{ $t("slot_text2") }}</a>
        </button>
      </div>
    </div>

    <!-- <div class="w-100 fw-bolder my-3">{{ $t("slot_text3") }}</div>
    <div class="w-100">
      <img
        :src="
          l.indexOf('A1-') != -1
            ? require('@/assets/img/allinslot/' + l.split('-')[1] + '.png')
            : require('@/assets/img/game/all/slot_' + l + '.png')
        "
        :class="css_list"
        @click="set_gametype(l.toUpperCase())"
        v-for="l in slot_list"
        :key="l"
      />
    </div> -->

    <newWindow
      :URL="newWindowurl"
      @iframe_close="iframeclosed"
      v-show="this.newWindowurl != ''"
    ></newWindow>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import newWindow from "./iframe.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "slot",
  props: { allinslot: Array },
  data() {
    return {
      code: Cookies.get("code"),
      css_list: "col-lg-2 col-6 mb-2 px-2 c-pointer",
      keyWord: "",
      maxVal: 0,
      newVal: 0,
      index: 1,
      newWindowurl: "",
    };
  },
  computed: {
    ...mapGetters(["all_game", "list"]),
    filterSearch() {
      return this.list.filter((v) => {
        if (this.keyWord.length > 0) {
          return v.gamename.toLowerCase().includes(this.keyWord.toLowerCase());
        } else return v;
      });
    },
    percent() {
      return this.newVal == 0
        ? 0
        : ((this.newVal * 100) / this.maxVal).toFixed();
    },
    type() {
      if (this.$route.params.type != null && this.$route.params.type != "") {
        if (this.$route.params.type.toLowerCase() == "hot") {
          return "hot";
        } else if (
          this.$route.params.sub_type != null &&
          this.$route.params.sub_type != ""
        )
          return this.$route.params.sub_type.toLowerCase();
        else return "";
      } else return "";
    },
    slot_list() {
      return this.allinslot.concat(this.all_game["slot"]);
    },
  },
  components: {
    newWindow,
  },
  watch: {
    type: function (e) {
      localStorage.setItem("g", e);
      this.gamelist(e);
    },
    filterSearch() {
      this.index = 1;
      this.maxVal = this.filterSearch.length > 0 ? this.filterSearch.length : 0;
      this.newVal =
        this.filterSearch.length > 20 ? 20 : this.filterSearch.length;
    },
    newWindowurl() {
      if (this.newWindowurl == "") {
        this.$store.commit("watch_newWindowurl", "close");
      }
    },
  },
  created() {
    this.gamelist(this.type);
  },
  mounted() {},
  methods: {
    ...mapActions(["getGameList"]),
    gamelist: function (t) {
      this.newVal = 0;

      var gametype =
        t.toUpperCase().indexOf("A1-") != -1
          ? t.toUpperCase().split("-")[0]
          : t;
      var type =
        t.toUpperCase().indexOf("A1-") != -1
          ? t.toUpperCase().split("-")[1]
          : "all";

      let param = {
        action: "GameLobby",
        body: {
          gametype: gametype,
          type: type,
        },
      };
      this.getGameList(param)
        .then(() => {
          this.index = 1;
          this.maxVal = this.list.length > 0 ? this.list.length : 0;
          this.newVal = this.list.length > 20 ? 20 : this.list.length;
          // var scr_h = document.getElementById(
          //   "casino-header-container"
          // ).offsetTop;
          // document.getElementById("content_page").scrollTop = scr_h - 80;

          document.getElementById("content_page").scrollTop = 0;
        })
        .catch();
    },
    game: function (device, mode, id, type, kind) {
      if (
        this.code == null ||
        this.code == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$router.push("Login");
        return;
      } else {
        if (id == "Coming Soon") {
          alert(this.$t("text_coming"));
        } else if (id == "GameLobby") {
          localStorage.setItem("g", type);
          this.$router.push({ name: "Game" });
        } else {
          if (type.toUpperCase() == "TCG" || type.toUpperCase() == "SB") {
            var isMobile = this.$Utils.isMobile();
            if (isMobile != "pc端") {
              device = "mobile";
            } else device = "web";
          }

          let param = {
            action: "stage_balance",
            body: {
              uid: localStorage.getItem("uid"),
              ip: localStorage.getItem("ip"),
              session_code: Cookies.get("code"),
              device: device,
              gametype: type,
              gamekind: kind,
              gameid: id,
              mode: mode,
            },
          };
          this.$store.dispatch("getpost", param).then((res) => {
            this.newWindowurl = res;
          });
        }
      }
    },
    more: function () {
      if (this.index <= Math.floor(this.maxVal / 20)) this.index++;
      this.newVal =
        this.list.length > 20 * this.index ? 20 * this.index : this.list.length;
    },
    iframeclosed(val) {
      this.newWindowurl = val;
    },
    set_gametype: function (e) {
      this.$router.push("/slot/slot/" + e);
    },
  },
};
</script>
