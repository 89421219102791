<template>
  <div v-if="isPreImg && flag_code_uid">
    <div
      class="prewindow_div"
      @click="this.$store.commit('PreImg', false)"
    ></div>
    <div class="prewin prewindow">
      <div class="popup-title">{{ $t("text_announcement") }}</div>
      <div class="prewin-swiper">
        <swiper
          :autoplay="swiper_options.autoplay"
          :loop="swiper_options.loop"
          :speed="swiper_options.speed"
          :slidesPerView="swiper_options.slidesPerView"
          :spaceBetween="swiper_options.spaceBetween"
          :breakpoints="swiper_options.breakpoints"
          :pagination="{ clickable: true }"
          :navigation="swiper_options.navigation"
        >
          <swiper-slide v-for="l in list" :key="l">
            <img v-lazy="l.jump_img" />
            <div class="item-title">{{ l.name }}</div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="popup-btn popup-menu-prev" @click="turn('left')">
        <svg viewBox="0 0 6 11" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.99801 5.45754C5.99837 5.37524 5.98133 5.2938 5.94801 5.21854C5.9105 5.13863 5.85628 5.0677 5.78901 5.01054L1.07301 0.626543C0.954548 0.514107 0.796282 0.453318 0.633011 0.457543C0.520898 0.45561 0.410121 0.4821 0.311011 0.534543C0.218538 0.583926 0.140618 0.656674 0.085011 0.745543C0.0292494 0.835607 0.000126449 0.939618 0.00101117 1.04554C0.000863417 1.1256 0.0175203 1.20479 0.0499046 1.278C0.082289 1.35121 0.12968 1.4168 0.189011 1.47054L4.48201 5.45754L0.189011 9.43654C0.130187 9.4899 0.0830317 9.55484 0.0505066 9.62729C0.0179816 9.69974 0.000789942 9.77813 1.12395e-05 9.85754C-0.000656602 9.96344 0.0284499 10.0674 0.0840111 10.1575C0.138947 10.2477 0.216968 10.3216 0.310011 10.3715C0.408583 10.4273 0.519773 10.4569 0.633011 10.4575C0.714131 10.4591 0.794761 10.4446 0.870269 10.4149C0.945778 10.3852 1.01468 10.3409 1.07301 10.2845L5.78901 5.89954C5.85542 5.84284 5.90895 5.77261 5.94601 5.69354C5.97978 5.61942 5.9975 5.539 5.99801 5.45754Z"
          ></path>
        </svg>
      </div>
      <div class="popup-btn popup-menu-next" @click="turn('right')">
        <svg viewBox="0 0 6 11" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.99801 5.45754C5.99837 5.37524 5.98133 5.2938 5.94801 5.21854C5.9105 5.13863 5.85628 5.0677 5.78901 5.01054L1.07301 0.626543C0.954548 0.514107 0.796282 0.453318 0.633011 0.457543C0.520898 0.45561 0.410121 0.4821 0.311011 0.534543C0.218538 0.583926 0.140618 0.656674 0.085011 0.745543C0.0292494 0.835607 0.000126449 0.939618 0.00101117 1.04554C0.000863417 1.1256 0.0175203 1.20479 0.0499046 1.278C0.082289 1.35121 0.12968 1.4168 0.189011 1.47054L4.48201 5.45754L0.189011 9.43654C0.130187 9.4899 0.0830317 9.55484 0.0505066 9.62729C0.0179816 9.69974 0.000789942 9.77813 1.12395e-05 9.85754C-0.000656602 9.96344 0.0284499 10.0674 0.0840111 10.1575C0.138947 10.2477 0.216968 10.3216 0.310011 10.3715C0.408583 10.4273 0.519773 10.4569 0.633011 10.4575C0.714131 10.4591 0.794761 10.4446 0.870269 10.4149C0.945778 10.3852 1.01468 10.3409 1.07301 10.2845L5.78901 5.89954C5.85542 5.84284 5.90895 5.77261 5.94601 5.69354C5.97978 5.61942 5.9975 5.539 5.99801 5.45754Z"
          ></path>
        </svg>
      </div>
      <div class="bump_close" @click="this.$store.commit('PreImg', false)">
        <span
          role="img"
          aria-label="close-circle"
          class="anticon anticon-close-circle"
          ><svg
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="close-circle"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"
            ></path>
            <path
              d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
            ></path>
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import { reactive } from "vue";
//引入swiper
import SwiperCore, {
  Autoplay,
  Pagination,
  EffectCoverflow,
  Navigation,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
//设置swiper
SwiperCore.use([Autoplay, Pagination, EffectCoverflow, Navigation]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      flag_code_uid:
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
          ? true
          : false,
      list: null,
    };
  },
  setup() {
    //指定swiper的设置
    let swiper_options = reactive({
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      loop: true,
      speed: 1000,
      slidesPerView: 1,
      pagination: {
        clickable: true,
      },
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
      breakpoints: {
        // when window width is >= 200px
        200: {
          slidesPerView: 1,
        },
        // when window width is >= 1200px
        1024: {
          slidesPerView: 3,
        },
      },
    });

    return {
      swiper_options,
    };
  },
  computed: {
    ...mapGetters(["isPreImg"]),
  },
  methods: {
    bannerlist: function () {
      let param = {
        action: "promotions",
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.list = res["data"];
      });
    },
    turn(e) {
      if (e == "left") {
        document.querySelector(".prewin .swiper-button-prev").click();
      } else {
        document.querySelector(".prewin .swiper-button-next").click();
      }
    },
  },
  watch: {},
  created() {
    this.bannerlist();
  },
};
</script>
