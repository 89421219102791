<template>
  <div>
    <div class="game_list w-100" :class="flag_banner_mar ? '' : 'mt-0'">
      <div class="list_card tab-title">
        <div class="game_list_content">
          <div
            class="list_name"
            @click="this.$router.push('/')"
            :class="g_type == '' ? 'list_hover' : ''"
          >
            <img src="@/assets/img/game_category/icon-all.png" alt="" />
            <span>{{ $t("text_home").toLowerCase() }}</span>
          </div>
          <div
            class="list_name"
            @click="this.$router.push('/slot/casino')"
            :class="g_type == 'casino' ? 'list_hover' : ''"
          >
            <img src="@/assets/img/game_category/icon-g-live.png" alt="" />
            <span>{{ $t("home_text6").toLowerCase() }}</span>
          </div>
          <div
            class="list_name"
            @click="this.$router.push('/slot/slot')"
            :class="g_type == 'slot' ? 'list_hover' : ''"
          >
            <img src="@/assets/img/game_category/icon-g-slot.png" alt="" />
            <span>{{ $t("home_text7").toLowerCase() }}</span>
          </div>
          <div
            class="list_name"
            @click="this.$router.push('/slot/sport')"
            :class="g_type == 'sport' ? 'list_hover' : ''"
          >
            <img src="@/assets/img/game_category/icon-g-sport.png" alt="" />
            <span>{{ $t("text_sport").toLowerCase() }}</span>
          </div>
          <div
            class="list_name"
            @click="this.$router.push('/slot/lotto')"
            :class="g_type == 'lotto' ? 'list_hover' : ''"
          >
            <img src="@/assets/img/game_category/icon-g-lotto.png" alt="" />
            <span>{{ $t("text_lotto").toLowerCase() }}</span>
          </div>
          <div
            class="list_name"
            @click="this.$router.push('/slot/fish')"
            :class="g_type == 'fish' ? 'list_hover' : ''"
          >
            <img src="@/assets/img/game_category/icon-g-fish.png" alt="" />
            <span>{{ $t("text_main12").toLowerCase() }}</span>
          </div>
          <!-- <div
            class="list_name"
            @click="this.$router.push('/slot/chess')"
            :class="g_type == 'chess' ? 'list_hover' : ''"
          >
            <img src="@/assets/img/game_category/icon-g-chess.png" alt="" />
            <span>{{ $t("text_chess").toLowerCase() }}</span>
          </div> -->
          <!-- <div
            class="list_name"
            @click="this.$router.push('/slot/hot')"
            :class="g_type == 'hot' ? 'list_hover' : ''"
          >
            <img src="@/assets/img/game_category/ic2_hot.png" alt="" />
            <span>{{ $t("text_main11").toLowerCase() }}</span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "category",
  data() {
    return {
      isMobile: this.$Utils.isMobile(),
    };
  },
  computed: {
    g_type() {
      if (this.$route.params.type != null) {
        if (this.$route.params.type.indexOf("A1-") != -1)
          return this.$route.params.type.split("-")[1];
        else return this.$route.params.type;
      } else return "";
    },
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
    flag_banner_mar() {
      if (
        this.isMobile != "pc端" &&
        (this.Id == "" || this.Id == "slot" || this.Id == "casino")
      ) {
        return true;
      } else if (this.isMobile == "pc端" && this.Id == "") {
        return true;
      } else return false;
    },
  },
  components: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
