<template>
  <div class="banner_div">
    <swiper
      :autoplay="swiper_options.autoplay"
      :loop="swiper_options.loop"
      :speed="swiper_options.speed"
      :slidesPerView="swiper_options.slidesPerView"
      :spaceBetween="swiper_options.spaceBetween"
      :breakpoints="swiper_options.breakpoints"
      :pagination="{ clickable: true }"
      :navigation="swiper_options.navigation"
    >
      <!-- <swiper-slide v-for="l in list" :key="l">
        <img :src="l" />
      </swiper-slide> -->
      <swiper-slide v-for="l in list" :key="l">
        <img :src="require('@/assets/img/banner/' + l + '.png')" />
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { reactive } from "vue";
//引入swiper
import SwiperCore, {
  Autoplay,
  Pagination,
  EffectCoverflow,
  Navigation,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
//设置swiper
SwiperCore.use([Autoplay, Pagination, EffectCoverflow, Navigation]);
export default {
  name: "banner",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return { list: ["1", "2", "3", "4", "5", "6"] };
  },
  setup() {
    //指定swiper的设置
    let swiper_options = reactive({
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      loop: true,
      speed: 1000,
      slidesPerView: 1,
      spaceBetween: 15,
      pagination: {
        clickable: true,
      },
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
      breakpoints: {
        // when window width is >= 200px
        200: {
          slidesPerView: 1,
        },
        // when window width is >= 1200px
        1200: {
          slidesPerView: 1,
        },
      },
    });

    return {
      swiper_options,
    };
  },
  methods: {
    bannerlist: function () {
      let param = {
        action: "banner",
        body: {
          device: this.$Utils.isMobile() != "pc端" ? "mobile" : "pc", //pc, mobile
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.list = res["urlList"];
      });
    },
  },
  computed: {
    // banner_we_mob() {
    //   return ["1", "2", "3", "4", "5", "6"];
    // },
  },
  mounted() {
    // this.bannerlist();
  },
};
</script>
